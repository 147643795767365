import React from 'react';
import styled from 'styled-components';

import Typography from 'ui/components/atoms/typography';

import { rem } from 'ui/helpers';

export type Props = {
  name: string,
  className?: string,
};

const Title = styled.div`
  width: ${rem(610)};
  height: ${rem(181)};
  padding: ${rem(36)};
  border: 4px solid white;
  border-radius: 20px;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const CurrentLiveExercise = ({ name, className }: Props) => (
  <Title className={className}>
    <Typography
      color="white"
      variant="double-pica"
    >
      {name}
    </Typography>
  </Title>
);

export default CurrentLiveExercise;
