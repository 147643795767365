import memoize from 'lodash/memoize';

import { CircuitType, Set, TimelineActivity, TimelineCircuit, ActivityMetrics } from 'types/graphql';

type Activity = Set & TimelineActivity;

export type MappedActivity = {
  endTime: number,
  startTime: number,
  name: string,
  type?: string,
  trackingConfig?: {
    scoreZones: {
      boundaries: Array<{
        start: number
      }>
    } | null,
    trackReps: boolean,
  },
  duration: number,
  metrics: ActivityMetrics,
  movementStandardUrl?: string,
};

type Segment = {
  type: string,
  activities: Activity[],
};

const getNameFromType = (type?: CircuitType | null) => {
  switch (type) {
    case CircuitType.WARMUP_SECTION:
      return 'Warm up';
    case CircuitType.TRANSITION_SECTION:
    case CircuitType.REST_SECTION:
      return 'Rest';
    case CircuitType.WORKOUT_SECTION:
    case CircuitType.HIIT:
      return 'Workout';
    case CircuitType.COOLDOWN_SECTION:
      return 'Cool down';
    case CircuitType.INTRO_SECTION:
      return 'Intro';
    case CircuitType.OUTRO_SECTION:
      return 'Wrap up';
    default:
      return 'Rest';
  }
};

const getRestDisplayName = (ac: any) => {
  switch (ac.type) {
    case 'OUTRO_SECTION':
      return 'Wrap up';
    case 'INTRO_SECTION':
      return 'Intro';
    default:
      return 'Rest';
  }
};

export const extractActivities = memoize((prev: TimelineCircuit[], { type, activities }: TimelineCircuit) => [
  ...prev,
  ...activities
    .map((ac: any) => ({ ...ac, type }))
    .reduce(
      (a: any[], b: { activities: Activity[]; }) => (b.activities ? [...a, ...b.activities] : [...a, b]), [],
    ),
]);

const getMovementStandardFromEx = (ac: any) => ((
  // Babel and optional chaining throws a fit here
  ac.exercises &&
    ac.exercises.length &&
    ac.exercises[0].exercise &&
    ac.exercises[0].exercise.movementStandard)
  ? ac.exercises[0].exercise.movementStandard.videoUrl
  : null);

export const mapActivities =
  memoize((timeline: TimelineCircuit[]): MappedActivity[] => timeline
    .reduce(extractActivities, [])
    .map(
      (ac: any, i: number, arr: any[]) => {
        const startTime = i >= 1 ? arr.slice(0, i).reduce((a, b) => a + b.duration, 0) : 0;
        const endTime = startTime + (ac.duration || 0);

        return ({
          ...ac,
          endTime,
          startTime,
          duration: ac.duration,
          name: ac.displayName || getRestDisplayName(ac),
          movementStandardUrl: !ac.displayName && !['INTRO_SECTION', 'OUTRO_SECTION'].includes(ac.type)
            ? ac.movementStandardUrl
            : getMovementStandardFromEx(ac),
        });
      },
    ));

type CircuitWithDuration = TimelineActivity & { duration?: number, activities?: TimelineActivity[] };

const calculateSectionDuration = (acc: number, current: CircuitWithDuration): number => {
  if (current.activities) {
    return current.activities.reduce(calculateSectionDuration, acc);
  }
  return current.duration ? acc + current.duration : acc;
};

export type MappedCircuit = {
  startTime: number,
  endTime: number,
  name: string,
  type: CircuitType,
  activities?: TimelineActivity[],
  duration: number,
};

export const mapSections = memoize((timeline: TimelineCircuit[]): MappedCircuit[] => (
  timeline.reduce((acc: any[], val, i) => {
    const startTime = i === 0 ? 0 : acc[i - 1].endTime;
    const duration = val.activities.reduce(calculateSectionDuration, 0);
    const endTime = startTime + duration;
    const name = val.name || getNameFromType(val.type);

    return [...acc, { ...val, startTime, duration, endTime, name }];
  }, [])
));

export const findCurrentActivity =
  (timeline: MappedActivity[], currentTime: number) => timeline.find(
    ({
      startTime,
      endTime,
    }: {
      startTime: number,
      endTime: number
    }) => startTime <= currentTime && endTime >= currentTime,
  );

export const findNextActivity = (timeline: MappedActivity[], currentTime: number) => {
  const currentActivityIndex = timeline.findIndex(({ startTime, endTime }: {
    startTime: number,
    endTime: number
  }) => startTime <= currentTime && endTime >= currentTime);

  return currentActivityIndex >= 0 ? timeline[currentActivityIndex + 1] : null;
};

export const findNextActivities = (timeline: MappedActivity[], currentTime: number) => {
  const currentActivityIndex = timeline.findIndex(({ startTime, endTime }: {
    startTime: number,
    endTime: number
  }) => startTime <= currentTime && endTime >= currentTime);

  return timeline.slice(currentActivityIndex + 1, Math.min(currentActivityIndex + 3, timeline.length));
};
