import React, { ReactChild } from 'react';
import styled from 'styled-components';

import Logo from 'ui/components/atoms/logo';
import Typography from 'ui/components/atoms/typography';
import RankingIcon from 'ui/components/atoms/icons/ranking';
import QRCode from 'ui/components/atoms/qr-code';

import { rem } from 'ui/helpers';

type Props = {
  className?: string,
  backgroundImage?: string,
  title: string,
  topScore?: number | null,
  leaderboard: ReactChild | null,
};

const Wrapper = styled.div<{ image?: string }>`
  display: flex;
  flex-direction: column;
  margin-left: 5.375rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(${({ image }) => `${image}?size=1920x1080&mask=full-background-image-gradient-720-v1`});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
    z-index: -1;
  }
`;

const LeaderboardContent = styled.div`
  width: ${rem(660)};
`;

const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

const Subtitle = styled(Typography)`
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

const LogoWrapper = styled.div`
  position: absolute;
  right: ${rem(80)};
  bottom: ${rem(70)};
  z-index: 1;
`;

const Content = styled.div`
  width: ${rem(1000)};
`;

const TitleSection = styled.div`
  min-height: ${rem(200)};
  padding: ${rem(150)} 0 ${rem(64)};
  z-index: 1;
  position: sticky;
  top: 0;
`;

const IconWrapper = styled.div`
  padding: ${rem(50)};
`;

const QRWrapper = styled.div`
  ${({ theme }) => `border: 2px solid ${theme.colors.classicGrey}`};
  display: flex;
  flex-direction: row;
  margin-top: ${rem(90)};
  padding: ${rem(24)};
  gap: ${rem(32)};
  align-items: center;
  border-radius: ${rem(8)};
`;

const QRCodeWrapper = styled.div`
  min-width: ${rem(142)};
`;

const QRText = styled.div`
  display: flex;
  flex-direction: column;
`;

const QRTextHeader = styled(Typography)`
  font-weight: 700;
`;

const PostSession = ({
  backgroundImage,
  title,
  topScore,
  leaderboard,
}: Props) => (
  <Wrapper image={backgroundImage}>
    <Content>
      <TitleSection>
        <Title variant="paragon" as="div">{title}</Title>
        {topScore && (<Subtitle variant="body-copy" color="lightGrey">Top score so far: {topScore}</Subtitle>)}
      </TitleSection>
      <LeaderboardContent>
        <IconWrapper>
          <RankingIcon />
        </IconWrapper>
        { leaderboard }
        <QRWrapper>
          <QRCodeWrapper>
            <QRCode url="https://fiitfeedback.typeform.com/studiofeedback" />
          </QRCodeWrapper>
          <QRText>
            <QRTextHeader>Share your thoughts!</QRTextHeader>
            <Typography>Scan the QR code to leave feedback on the class.</Typography>
          </QRText>
        </QRWrapper>
      </LeaderboardContent>

    </Content>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
  </Wrapper>
);

PostSession.defaultProps = {
  gradientStyle: 'sharp',
} as Partial<Props>;

export default PostSession;
