import React from 'react';

import LiveExerciseDetails from 'ui/components/molecules/live-exercise-details';

import {
  findCurrentActivity,
  findNextActivities,
  MappedActivity,
  MappedCircuit,
} from 'ui/components/molecules/current-exercise/utils';

type Props = {
  currentTime: number,
  timeline: MappedActivity[],
  circuits: MappedCircuit[],
};

export const LiveExercise = ({ currentTime, timeline, circuits }: Props) => {
  const current = findCurrentActivity(timeline, currentTime);
  const next = findNextActivities(timeline, currentTime);

  if (!current) {
    return null;
  }

  return (
    <LiveExerciseDetails
      currentTime={currentTime - current.startTime}
      duration={current.duration}
      name={current.name}
      movementStandardUrl={current.movementStandardUrl}
      next={next}
      circuits={circuits}
    />
  );
};

export default LiveExercise;
