/* eslint-disable no-process-env, prefer-object-spread, max-len */
import config, { bool, num } from 'config/config';

const FALCON_D1_USER_AGENT = /ES140/;
// If we can't get a user agent have to assume we're in the worst conditions
const isGPUConstrained = FALCON_D1_USER_AGENT.test(global?.navigator?.userAgent || 'ES140');

// We need to use the keyboard on specific sky boxes (SOIP)
const SKY_SOIP_USER_AGENT = /(LlamaUK|XiOne)/;
const skyShowKeyboard = SKY_SOIP_USER_AGENT.test(global?.navigator?.userAgent || '');

// we're using js so we can pull it more easily into webpack
// Update Config.tsx with types to match this
const exported = Object.assign({}, config, {
  TV_BASE_LINK: {
    value: process.env.REACT_APP_TV_BASE_LINK,
    default: null,
  },
  TV_PRODUCT_NAME: {
    value: process.env.REACT_APP_TV_PRODUCT_NAME,
    default: null,
  },
  TV_QR_LINK: {
    value: process.env.REACT_APP_TV_QR_LINK,
    default: null,
  },
  MAX_LESSONS_IN_GRID: {
    value: num(process.env.REACT_APP_MAX_LESSONS_IN_GRID),
    default: 64,
  },
  BROWSE_PAGE_COLLECTION_RAILS_SLUG: {
    value: process.env.REACT_APP_BROWSE_PAGE_COLLECTION_RAILS_SLUG,
  },
  BROWSE_PAGE_COLLECTION_BROWSE_COLLECTIONS_SLUG: {
    value: process.env.REACT_APP_BROWSE_PAGE_COLLECTION_BROWSE_COLLECTIONS_SLUG,
  },
  SHOW_INTRO_VIDEO_ONCE: {
    value: bool(process.env.REACT_APP_SHOW_INTRO_VIDEO_ONCE),
    format: Boolean,
    default: false,
  },
  HIDE_INTRO_VIDEO: {
    value: bool(process.env.REACT_APP_HIDE_INTRO_VIDEO),
    format: Boolean,
    default: false,
  },
  BROWSE_PAGE_TRENDING_RAIL_LENGTH: {
    value: num(process.env.REACT_APP_BROWSE_PAGE_TRENDING_RAIL_LENGTH),
    default: 10,
  },
  FAVOURITES_POLLING_INTERVAL: {
    value: num(process.env.REACT_APP_FAVOURITES_POLLING_INTERVAL),
    default: 5,
  },
  ACTIVE_TRAINING_PLAN_POLLING_INTERVAL: {
    value: num(process.env.REACT_APP_ACTIVE_TRAINING_PLAN_POLLING_INTERVAL),
    default: 10,
  },
  BROWSE_PAGE_TRANSITION_MINIMUM_DELAY_MS: {
    value: isGPUConstrained ? num(process.env.REACT_APP_BROWSE_PAGE_TRANSITION_MINIMUM_DELAY_MS) : 0,
    default: 0,
  },
  BACK_TRANSITION_MINIMUM_DELAY_MS: {
    value: num(process.env.REACT_APP_BACK_TRANSITION_MINIMUM_DELAY_MS),
    default: 0,
  },
  FINISH_WORKOUT_DELAY: {
    value: num(process.env.REACT_APP_FINISH_WORKOUT_DELAY),
    default: 6,
  },
  AMAZON_BILLING_ENABLED: {
    value: bool(process.env.REACT_APP_AMAZON_BILLING_ENABLED),
    format: Boolean,
    default: false,
  },
  DEFAULT_DEBUG_DASH_MANIFEST: {
    value: process.env.REACT_APP_DEFAULT_DEBUG_DASH_MANIFEST,
    default: 'https://fiit-download.s3-eu-west-1.amazonaws.com/testing-video-assets/74d43a85-e329-4cd8-ba10-63915534079c/raw.mpd',
  },
  DEBUG_LESSON_MEDIA_ENABLED: {
    value: bool(process.env.REACT_APP_DEBUG_LESSON_MEDIA_ENABLED),
    default: false,
  },
  WS_MOCKING_ENABLED: {
    value: bool(process.env.REACT_APP_WS_MOCKING_ENABLED),
    default: false,
  },
  API_ENVIRONMENT: {
    value: process.env.REACT_APP_API_ENVIRONMENT,
    default: 'staging',
  },
  ENABLE_PLAYREADY_AUTH_PROXY: {
    value: bool(process.env.REACT_APP_ENABLE_PLAYREADY_AUTH_PROXY),
    format: Boolean,
    default: false,
  },
  ENABLE_CUSTOM_ON_SCREEN_KEYBOARD: {
    value: skyShowKeyboard || bool(process.env.REACT_APP_ENABLE_CUSTOM_ON_SCREEN_KEYBOARD),
    default: false,
  },
  SCREEN_IDLE_TIME_SECS: {
    value: num(process.env.REACT_APP_SCREEN_IDLE_TIME_SECS),
    default: 300, // 5 mins
  },
  TIZEN_KEY_RETRY_MS: {
    value: num(process.env.REACT_APP_TIZEN_KEY_RETRY_MS),
    default: 1000,
  },
  CHROMECAST_CHANNEL: {
    value: process.env.REACT_APP_CHROMECAST_CHANNEL,
    default: 'urn:x-cast:com.tv.fiit',
  },
  CHROMECAST_MESSAGE_DELAY_MS: {
    value: num(process.env.REACT_APP_CHROMECAST_MESSAGE_DELAY_MS),
    default: 5000,
  },
  ENABLE_WORKOUT_STORAGE: {
    value: bool(process.env.REACT_APP_ENABLE_WORKOUT_STORAGE),
    format: Boolean,
    default: false,
  },
});

export default exported;
