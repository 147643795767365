import React, { ReactChild } from 'react';
import styled from 'styled-components';

import Logo from 'ui/components/atoms/logo';
import Video from 'ui/components/atoms/video-player';
import Watch from 'ui/components/atoms/icons/watch';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';

type Props = {
  className?: string,
  backgroundImage?: string,
  qrCode?: ReactChild,
  title: string,
  subtitle: string,
  length: string,
  countdown: ReactChild | null,
  leaderboard: ReactChild | null,
  dashUrl?: string | null,
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100vh;
`;

const Column = styled.div`
  height: 100%;
  width: 75%;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.xs} 0.4rem;
  position: absolute;
`;

const LeaderboardContent = styled(Column)`
  position: absolute;
  height: 100%;
  width: 25%;
  right: 0;
  top: 0;
  margin: 0 1% 3rem;
`;

const TitleContainer = styled.div<{ backgroundImage?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 1em;
  width: ${rem(300)};
  height: ${rem(300)};
  background: url(${({ backgroundImage }) => backgroundImage}) no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: ${({ theme }) => theme.spacing.m};

  @media (max-width: 80rem) {
    width: ${rem(270)};
    height: ${rem(270)};
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 1em;
  top: 1em;
  z-index: 1;
`;

const LeftContainer = styled.div`
  position: absolute;
  left: 3.5em;
  top: 10em;
  width: ${rem(300)};
  z-index: 1;

  @media (max-width: 80rem) {
    width: ${rem(270)};
    left: 4em;
  }
`;

const CountdownContainer = styled.div`
  position: absolute;
  right: 1em;
  bottom: 1em;
  z-index: 1;
`;

const Title = styled(Typography)`
  text-align: center;
  font-size: ${rem(38)};
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

const Subtitle = styled(Typography)`
  text-align: center;
  font-size: ${rem(20)};
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

const StyledWatch = styled(Watch)`
  height: 0.8rem;
  width: 0.8rem;
  margin-right: 0.4rem;
`;

const VideoWrapper = styled.div`
  width: 100%;
`;

const Lobby = ({
  className = '',
  backgroundImage,
  qrCode,
  title,
  subtitle,
  length,
  countdown,
  leaderboard,
  dashUrl,
}: Props) => (
  <Wrapper>
    <VideoWrapper>
      <Video src={{ dashUrl }} muted playing loop />
      <LeftContainer>
        <TitleContainer backgroundImage={backgroundImage}>
          <Title variant="paragon">{title}</Title>
          <Subtitle variant="body-copy">{subtitle}</Subtitle>
          { length && <Typography variant="pica"><StyledWatch />{length}</Typography> }
        </TitleContainer>
        { qrCode && <div>{ qrCode }</div> }
      </LeftContainer>
    </VideoWrapper>

    <Column
      className={className}
    >
      <LogoContainer>
        <Logo color="white" />
      </LogoContainer>

      { countdown && <CountdownContainer>{ countdown }</CountdownContainer> }
    </Column>
    <LeaderboardContent>
      { leaderboard }
    </LeaderboardContent>
  </Wrapper>
);

Lobby.defaultProps = {
  gradientStyle: 'sharp',
} as Partial<Props>;

export default Lobby;
