import React, { useEffect } from 'react';

import useConfig, { Config } from 'app/in-studio/config-provider';

import LobbyLayout from 'ui/components/layouts/lobby';

import QRCodeDisplay from 'ui/components/molecules/qr-code-display';
import Leaderboard from 'ui/components/molecules/leaderboard';
import ClassCountdown from 'ui/components/atoms/class-countdown';
import { labelFromLessonDuration } from 'ui/components/atoms/class-length';

import { trainerNames } from 'ui/components/utils/trainer-names';
import { useAppState, useDispatch } from 'state';

import { LessonInstanceByIdQuery } from 'app/in-studio/types/graphql';
import { LessonInstanceStatus } from 'state/lesson-instance';
import { setLessonInstanceStatus } from 'actions/lesson-instance';

type LessonInstance = LessonInstanceByIdQuery['lessonInstanceById'];

export type Props = {
  lessonInstance: LessonInstance,
};

const generateURL = (lessonInstance: LessonInstance, config: Config, userId: number | null) => {
  const { id, lesson } = lessonInstance;
  const mediaId = lesson.defaultMediaOption?.id;
  const context = `studio_${userId || 'unknown'}`;

  const path = `${config.QR_CODE_HOSTNAME}/class-instances/${id}/lobby`;

  return `${path}?lesson_media_id=${mediaId}&hide_video=true&location_context=${context}`;
};

const Lobby = ({ lessonInstance }: Props) => {
  const { config } = useConfig();
  const dispatch = useDispatch();

  const lobbyEntries = useAppState((appState) => (
    appState.lessonInstance[lessonInstance.id]?.lobby.entries || []
  ));

  const connected = useAppState((appState) => (
    appState.lessonInstance[lessonInstance.id]?.connected || false
  ));

  const status = useAppState((appState) => (
    appState.lessonInstance[lessonInstance.id]?.status || LessonInstanceStatus.UNKNOWN
  ));

  const userId = useAppState((state) => state.auth.userId);

  useEffect(() => {
    const start = lessonInstance.workoutStartTime
      ? new Date(lessonInstance.workoutStartTime).getTime()
      : new Date().getTime();

    const interval = setInterval(() => {
      if (
        !connected && new Date().getTime() > start &&
        ![LessonInstanceStatus.COUNTDOWN, LessonInstanceStatus.IN_SESSION].includes(status)
      ) {
        dispatch(setLessonInstanceStatus({
          lessonInstanceId: lessonInstance.id,
          status: LessonInstanceStatus.IN_SESSION,
        }));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [connected, dispatch, lessonInstance, status]);

  if (!lessonInstance.lesson) {
    return <div>Error screen</div>;
  }

  return (
    <LobbyLayout
      backgroundImage={lessonInstance.lesson.mainImage?.url &&
        `${lessonInstance.lesson.mainImage?.url}?mask=dark-gradient-bottom-v1`}
      qrCode={(
        <QRCodeDisplay
          url={generateURL(lessonInstance, config, userId)}
        />
      )}
      title={lessonInstance.lesson.name}
      subtitle={trainerNames(lessonInstance.lesson.trainers, 'with')}
      length={labelFromLessonDuration(lessonInstance.lesson.durationRange)}
      countdown={(
        lessonInstance.workoutStartTime ? (
          <ClassCountdown
            text="Starts in"
            workoutStartTime={lessonInstance.workoutStartTime}
          />
        ) : null
      )}
      leaderboard={(
        <Leaderboard entries={lobbyEntries} isStatic connected={connected} showCounter />
      )}
      dashUrl={lessonInstance.lesson.studio.prerollVideo?.url}
    />
  );
};

export default Lobby;
