import React from 'react';
import styled from 'styled-components';

import UIPanel from 'ui/components/atoms/ui-panel';
import Typography from 'ui/components/atoms/typography';
import Time from 'ui/components/atoms/time';
import CurrentLiveExercise from 'ui/components/atoms/current-live-exercise';
import NextLiveExercise from 'ui/components/atoms/next-live-exercise';
import ClassTemplate from 'ui/components/atoms/class-template';

import ProgressCircle from 'ui/components/molecules/progress-circle';

import {
  MappedActivity,
  MappedCircuit,
} from 'ui/components/molecules/current-exercise/utils';

import { rem } from 'ui/helpers';

export type Props = {
  className?: string,
  currentTime: number,
  duration: number,
  name: string,
  next: MappedActivity[],
  movementStandardUrl?: string,
  circuits: MappedCircuit[],
};

const Wrapper = styled(UIPanel)`
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  margin: ${rem(42)} 0 auto ${rem(80)};
  -webkit-mask-image: -webkit-gradient(
    linear,
    left 80%,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
`;

const TextColumn = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const VidColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClassTemplateCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeSpan = styled(Typography)`
  display: inline-block;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
`;

const StyledCurrentLiveExercise = styled(CurrentLiveExercise)`
  margin-top: ${rem(60)};
`;

const StyledVideo = styled.video`
  margin: 0 auto;
  width: 85%;
  border-radius: 20px;
`;

const getDurationFromExercise = ({ duration }: MappedActivity) => {
  const min = Math.floor(duration / 60);
  const sec = duration % 60;

  const formatDuration = () => {
    if (min && sec) {
      return `${min} min
      ${sec} sec`;
    }
    if (min) {
      return `${min} min`;
    }
    return `${sec} sec`;
  };

  return formatDuration();
};

export const LiveExerciseDetails = ({
  className,
  currentTime = 0,
  duration,
  name,
  movementStandardUrl,
  next,
  circuits,
}: Props) => {
  const activeColor = !['Intro', 'Rest', 'Outro'].includes(name);
  const isIntro = name === 'Intro';
  return (
    <Wrapper className={className}>
      <TextColumn>
        <ProgressCircle value={currentTime} maxValue={duration} activeColor={activeColor}>
          <TimeSpan
            variant="paragon"
            lineHeight="m"
          >
            <Time time={duration - currentTime} />
          </TimeSpan>
        </ProgressCircle>

        <StyledCurrentLiveExercise name={name} className="StyledCurrentLiveExercise" />
        { next && (
          next.map((ex, i) => (
            <NextLiveExercise name={ex.name} duration={getDurationFromExercise(ex)} key={`${ex.name}_${i}`} />
          ))
        )}
      </TextColumn>
      { movementStandardUrl && (duration > currentTime) && (
        <VidColumn>
          <StyledVideo muted loop autoPlay src={movementStandardUrl} />
        </VidColumn>
      )}
      { isIntro && (
        <ClassTemplateCol>
          <ClassTemplate circuits={circuits} />
        </ClassTemplateCol>
      )}

    </Wrapper>
  );
};

export default LiveExerciseDetails;
