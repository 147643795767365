import React, { useEffect, useState, useCallback, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useDismissEvent from 'app/hooks/use-dismiss-event';
import useConfig from 'app/in-studio/config-provider';

import Button from 'ui/components/atoms/button';

import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { ProfileQuery, ProfileQueryVariables } from 'app/in-studio/types/graphql';
import { Profile as PROFILE_QUERY } from 'app/in-studio/pages/profile/profile-query.gql';
import { useAppState, useDispatch } from 'state';
import { logout } from 'actions/auth';
import LogoutScreen from 'app/in-studio/organisms/logout-screen';
import SvgLogout from 'ui/components/atoms/icons/logout';
import useRoutes from 'utils/use-routes';
import { WebsocketClientContext } from 'utils/apollo-client';

type ComponentProps = {
  slug: string,
};

export type Props = RouteComponentProps<ComponentProps>;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  position: relative;
`;

const Heading = styled(Typography)`
  margin-top: ${rem(40)};
  margin-bottom: ${rem(20)};
`;

const Subheading = styled(Typography)`
  margin-bottom: ${rem(40)};
  color: ${({ theme }) => theme.colors.beckersKnop};
  max-width: ${rem(1000)};
`;

const Version = styled(Typography)`
  margin-bottom: ${rem(40)};
  color: ${({ theme }) => theme.colors.classicGrey};
  max-width: ${rem(1000)};
`;

const ProfilePage = () => {
  const client = useApolloClient();
  const { client: wsClient } = useContext(WebsocketClientContext);
  const dispatch = useDispatch();
  const { redirect, routes } = useRoutes();
  const { config } = useConfig();

  const [logoutVisible, setLogoutVisible] = useState(false);
  const showLogout = useCallback(() => {
    setLogoutVisible(true);
  }, []);

  const cancelLogout = useCallback(() => {
    setLogoutVisible(false);
  }, []);

  const logoutAction = useCallback(async () => {
    setLogoutVisible(false);
    await client.clearStore();
    await wsClient?.close();
    dispatch(logout());
    redirect({ route: routes.LOGIN, replaceStack: true, queryParams: { hideIntroVideo: true } });
  }, [wsClient, client, dispatch, redirect, routes.LOGIN]);

  const { data, refetch: refetchUser } = useQuery<ProfileQuery, ProfileQueryVariables>(PROFILE_QUERY);

  const userId = useAppState((state) => state.auth.userId);
  const platformPartner = useAppState((state) => state.platformPartner);

  useDismissEvent();

  useEffect(() => {
    refetchUser();
  }, [userId, refetchUser]);

  const user = data?.auth?.user;

  return (
    <>
      <Wrapper>
        <Heading variant="double-pica" weight="bold">{platformPartner.name}</Heading>
        <Subheading>
          {user?.email}
        </Subheading>
        <Version>
          Web App Version: {config.VERSION}
        </Version>
        <Button
          label="Log out"
          variant="cta"
          autofocus
          onClick={showLogout}
          icon={<SvgLogout />}
        />
      </Wrapper>
      {logoutVisible && <LogoutScreen cancelLogout={cancelLogout} logoutAction={logoutAction} />}
    </>
  );
};

ProfilePage.menu = true;

export default ProfilePage;
